// useAmountFormatter.js
export default function useAmountFormatter() {
    const currencyFormatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

    function formatAmount(amount) {
      if (typeof amount !== 'number') {
        return '';
      }
      
      return currencyFormatter.format(amount);
    }
  
    return {
      formatAmount
    };
}